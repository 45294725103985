import { combineReducers, configureStore } from '@reduxjs/toolkit';
import api from './middleware/api';
import auth from './auth';
import users from './users';
import apiError from './middleware/apiError';
import sessions from './sessions';
import files from './files';

const store = () =>
  configureStore({
    reducer: combineReducers({
      auth,
      users,
      sessions,
      files,
    }),
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      api,
      apiError,
    ],
  });

export default store;
