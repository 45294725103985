// noinspection JSCheckFunctionSignatures

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'files',
  initialState: {
    list: {
      totalDocs: 0,
      hasNextPage: true,
      docs: [],
    },
    loading: false,
    error: null,
  },
  reducers: {
    filesRequestStarted: (files) => {
      files.loading = true;
    },
    filesRequestFailed: (files, action) => {
      files.error = action.payload.data;
    },
    filesRequestEnded: (files) => {
      files.loading = false;
    },
    filesReceived: (files, action) => {
      files.list = action.payload.data;
    },
  },
});

export const {
  filesRequestStarted,
  filesRequestFailed,
  filesRequestEnded,
  filesReceived,
} = slice.actions;
export default slice.reducer;

//api calls
export const getAll = (params) =>
  apiCallBegan({
    url: '/files',
    params,
    onStart: filesRequestStarted,
    onSuccess: filesReceived,
    onError: filesRequestFailed,
    onEnd: filesRequestEnded,
  });

export const remove = (id) =>
  apiCallBegan({
    url: `/files/${id}`,
    method: 'DELETE',
    onStart: filesRequestStarted,
    onSuccess: () => getAll(),
    onError: filesRequestFailed,
    onEnd: filesRequestEnded,
  });

// Action 1 (for upload file): Generate pre-signed URL
export const generatePreSignedUrl = (filename) =>
  apiCallBegan({
    url: '/files/generate-upload-url',
    method: 'POST',
    data: { filename },
    onError: filesRequestFailed,
    onEnd: filesRequestEnded,
  });

// Action 2 (for upload file): Upload file
export const uploadFile = (preSignedUrl, file, onUploadProgress) =>
  apiCallBegan({
    url: preSignedUrl,
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    data: file,
    onUploadProgress,
  });

// Action 3 (for upload file): Send metadata
export const sendMetadata = (data) =>
  apiCallBegan({
    url: '/files/metadata',
    method: 'POST',
    data,
    onSuccess: () => getAll(),
  });

export const getSignedUrl = (key) =>
  apiCallBegan({
    url: `/files/signed-url`,
    params: { key },
    onStart: filesRequestStarted,
    onEnd: filesRequestEnded,
  });

//selector
export const selectFiles = createSelector(
  (state) => state.files,
  (files) => ({
    list: files.list,
    loading: files.loading,
  }),
);
