import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import * as yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { MuiFileInput } from 'mui-file-input';
import { useToggler } from 'hooks/use-toggler';
import { styled } from '@mui/material/styles';
import { generatePreSignedUrl, sendMetadata, uploadFile } from 'store/files';
import { enqueueSnackbar } from 'notistack';
import { ROOMS } from 'config/files';

const validationSchema = yup.object().shape({
  room: yup.string().required(),
  file: yup.mixed().required(),
});

const initialValues = {
  room: '',
  file: null,
};

const FileInput = styled(MuiFileInput)({
  '.MuiInputBase-root': {
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  '& .MuiInputAdornment-positionStart': {
    marginTop: '0 !important',
  },
  '& .MuiInputAdornment-positionEnd': {
    marginLeft: 'auto',
  },
});

const UploadModal = () => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const { open, handleOpen, handleClose } = useToggler();

  const handleSubmit = useCallback(
    async (values, { resetForm }) => {
      const { room, file } = values;

      try {
        const { data: preSignedUrl } = await dispatch(
          generatePreSignedUrl(file.name),
        );
        setUploading(true);
        await dispatch(
          uploadFile(preSignedUrl, file, (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            setProgress(percentCompleted);
          }),
        );
        await dispatch(
          sendMetadata({ room, filename: file.name, size: file.size }),
        );
        resetForm();
        handleClose();
      } catch (e) {
        enqueueSnackbar(e.response?.data.message, { variant: 'error' });
      } finally {
        setUploading(false);
        setProgress(0);
      }
    },
    [dispatch, handleClose],
  );

  return (
    <>
      <Button
        startIcon={
          <SvgIcon fontSize="small">
            <UploadIcon />
          </SvgIcon>
        }
        onClick={handleOpen}
        variant="contained"
      >
        Upload
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload archive</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              <DialogContent>
                <Field
                  as={TextField}
                  helperText={<ErrorMessage name="room" />}
                  error={props.errors.room && props.touched.room}
                  margin="normal"
                  required
                  fullWidth
                  select
                  label="Room"
                  id="room"
                  name="room"
                >
                  {ROOMS.map((room) => (
                    <MenuItem key={room} value={room}>
                      {room}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  as={FileInput}
                  helperText={<ErrorMessage name="file" />}
                  error={props.errors.file && props.touched.file}
                  margin="normal"
                  inputProps={{
                    accept: '.zip,.rar,.7z,.gz',
                  }}
                  sx={{
                    justifyContent: 'space-between',
                  }}
                  fullWidth
                  id="file"
                  placeholder="Choose file"
                  name="file"
                  onChange={(value) => props.setFieldValue('file', value)}
                />
              </DialogContent>
              {uploading && (
                <Box
                  display="flex"
                  alignItems="center"
                  gap={16}
                  sx={{
                    px: 3,
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <LinearProgress variant="determinate" value={progress} />
                  </Box>
                  <Box minWidth={35}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >{`${progress}%`}</Typography>
                  </Box>
                </Box>
              )}
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={uploading} type="submit">
                  Upload
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default UploadModal;
