// noinspection JSCheckFunctionSignatures

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'users',
  initialState: {
    list: {
      totalDocs: 0,
      hasNextPage: true,
      docs: [],
    },
    current: null,
    loading: false,
  },
  reducers: {
    usersRequested: (users) => {
      users.loading = true;
    },
    usersRequestFailed: (users, action) => {
      users.error = action.payload.data;
    },
    usersRequestEnded: (users) => {
      users.loading = false;
    },
    usersReceived: (users, action) => {
      users.list = action.payload.data;
    },
    usersLoaded: (users, action) => {
      users.list = {
        ...action.payload.data,
        docs: [...users.list.docs, ...action.payload.data.docs],
      };
    },
    currentUserReceived: (users, action) => {
      users.current = action.payload.data;
    },
  },
});

export const {
  usersRequested,
  usersRequestFailed,
  usersRequestEnded,
  usersReceived,
  currentUserReceived,
} = slice.actions;
export default slice.reducer;

//api calls
export const getAll = (params) =>
  apiCallBegan({
    url: '/users',
    params,
    onStart: usersRequested,
    onSuccess: usersReceived,
    onError: usersRequestFailed,
    onEnd: usersRequestEnded,
  });

export const getUserById = (id) =>
  apiCallBegan({
    url: `/users/${id}`,
    onStart: usersRequested,
    onSuccess: currentUserReceived,
    onError: usersRequestFailed,
    onEnd: usersRequestEnded,
  });

export const getOne = (params) =>
  apiCallBegan({
    url: `/users/one`,
    params,
  });

export const createUser = (data) =>
  apiCallBegan({
    url: '/users',
    method: 'post',
    data,
    onStart: usersRequested,
    onSuccess: () => getAll(),
    onEnd: usersRequestEnded,
  });

export const updateUser = (id, data) =>
  apiCallBegan({
    url: `/users/${id}`,
    method: 'put',
    data,
    onSuccess: () => getAll(),
    onStart: usersRequested,
    onEnd: usersRequestEnded,
  });

export const blockUser = (id) =>
  apiCallBegan({
    url: `/users/${id}`,
    method: 'delete',
    onStart: usersRequested,
    onSuccess: () => getAll(),
    onError: usersRequestFailed,
    onEnd: usersRequestEnded,
  });

export const unblockUser = (id) =>
  apiCallBegan({
    url: `/users/${id}/unblock`,
    method: 'patch',
    onStart: usersRequested,
    onSuccess: () => getAll(),
    onError: usersRequestFailed,
    onEnd: usersRequestEnded,
  });

export const removeUser = (id) =>
  apiCallBegan({
    url: `/users/${id}/remove`,
    method: 'delete',
    onStart: usersRequested,
    onSuccess: () => getAll(),
    onError: usersRequestFailed,
    onEnd: usersRequestEnded,
  });
export const resetPassword = (id, data) =>
  apiCallBegan({
    url: `/users/${id}/reset-password`,
    method: 'patch',
    data,
    onStart: usersRequested,
    onSuccess: () => getAll(),
    onError: usersRequestFailed,
    onEnd: usersRequestEnded,
  });

export const checkUsername = (username) =>
  apiCallBegan({
    url: `/users/check-username`,
    method: 'post',
    data: { username },
  });

export const trackVisit = () =>
  apiCallBegan({ url: `/users/visit`, method: 'patch' });

export const searchUsers = (name, params = {}) =>
  apiCallBegan({
    url: '/users/search',
    params: { name, ...params },
  });

export const getRoles = () =>
  apiCallBegan({
    url: '/roles',
  });

//selector
export const selectList = createSelector(
  (state) => state.users,
  (users) => ({
    list: users.list,
    loading: users.loading,
  }),
);

export const selectCurrentUser = createSelector(
  (state) => state.users,
  (users) => ({
    user: users.current,
    loading: users.loading,
  }),
);
