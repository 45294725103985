import { Route, Routes } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Container } from '@mui/material';
import { useToggler } from 'hooks/use-toggler';
import IndexPage from 'pages/admin/index';
import UsersPage from 'pages/admin/users';
import NotFoundPage from 'pages/404';
import TopNav from './TopNav';
import SideNav from './SideNav';

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
});

const AdminDashboard = () => {
  const { open, handleOpen, handleClose } = useToggler();

  return (
    <>
      <TopNav onNavOpen={handleOpen} />
      <SideNav onClose={handleClose} open={open} />
      <LayoutRoot>
        <LayoutContainer>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 8,
            }}
          >
            <Container maxWidth="xl">
              <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="/users" element={<UsersPage />} />
                <Route path="/*" element={<NotFoundPage />} />
              </Routes>
            </Container>
          </Box>
        </LayoutContainer>
      </LayoutRoot>
    </>
  );
};

export default AdminDashboard;
