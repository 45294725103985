import { SvgIcon } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

export const items = [
  {
    title: 'Files',
    path: '/',
    icon: (
      <SvgIcon fontSize="small">
        <DashboardIcon />
      </SvgIcon>
    ),
  },
  {
    title: 'Users',
    path: '/users',
    icon: (
      <SvgIcon fontSize="small">
        <PeopleOutlineIcon />
      </SvgIcon>
    ),
  },
];
