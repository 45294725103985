import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import * as yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useToggler } from 'hooks/use-toggler';
import { getUserById, updateUser, selectCurrentUser } from 'store/users';

const validationSchema = yup.object().shape({
  discord: yup.string(),
});

const UpdateModal = ({ userId }) => {
  const dispatch = useDispatch();
  const { open, handleOpen, handleClose } = useToggler();
  const { user, loading } = useSelector(selectCurrentUser);

  useEffect(() => {
    if (open) {
      dispatch(getUserById(userId));
    }
  }, [open, dispatch, userId]);

  const initialValues = useMemo(
    () => ({
      username: user?.username,
      discord: user?.discord,
    }),
    [user],
  );

  const handleSubmit = useCallback(
    async (values, { resetForm }) => {
      try {
        await dispatch(updateUser(userId, values));
        resetForm();
        handleClose();
        enqueueSnackbar('Successfully updated', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(e.response?.data.message, { variant: 'error' });
      }
    },
    [dispatch, userId, handleClose],
  );

  return (
    <>
      <GridActionsCellItem
        label="Update"
        icon={<EditIcon />}
        onClick={handleOpen}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update {user?.username}</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(props) => (
            <Form>
              <DialogContent>
                <Field
                  as={TextField}
                  disabled
                  margin="normal"
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                />
                <Field
                  as={TextField}
                  helperText={<ErrorMessage name="discord" />}
                  error={props.errors.discord && props.touched.discord}
                  margin="normal"
                  fullWidth
                  id="discord"
                  label="Discord"
                  name="discord"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={loading} type="submit">
                  Update
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default UpdateModal;
