import { Box, Card, Stack, Typography } from '@mui/material';
import ArchivesTable from 'components/ArchivesTable/ArchivesTable';
import UploadModal from './_upload';

const IndexPage = () => (
  <Stack spacing={3}>
    <Stack direction="row" justifyContent="space-between" spacing={4}>
      <Stack spacing={1}>
        <Typography variant="h4">Files</Typography>
      </Stack>
      <div>
        <UploadModal />
      </div>
    </Stack>
    <Card>
      <Box sx={{ minWidth: 800 }}>
        <ArchivesTable />
      </Box>
    </Card>
  </Stack>
);

export default IndexPage;
