import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@mui/material';
import debounce from 'lodash.debounce';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import BadgeIcon from '@mui/icons-material/Badge';
import * as yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { useToggler } from 'hooks/use-toggler';
import { fakerEN as faker } from '@faker-js/faker';
import { createUser, checkUsername } from 'store/users';

const initialValues = {
  username: '',
  discord: '',
  password: '',
};

const validationSchema = yup.object().shape({
  username: yup.string().required(),
  // .test('checkUsername', 'username is taken', handleCheckUsername),

  discord: yup.string(),

  password: yup.string().required(),
});

const CreateModal = ({ loading }) => {
  const dispatch = useDispatch();
  const { open, handleOpen, handleClose } = useToggler();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(
    () => setShowPassword((show) => !show),
    [],
  );

  const handleMouseDownPassword = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleSubmit = useCallback(
    async (values, { resetForm }) => {
      try {
        await dispatch(createUser(values));
        resetForm();
        handleClose();
        enqueueSnackbar('Successfully created', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(e.response?.data.message, { variant: 'error' });
      }
    },
    [handleClose, dispatch],
  );

  const handleCheckUsername = debounce(
    useCallback(
      async (username, cb) => {
        try {
          await dispatch(checkUsername(username));
          return true;
        } catch (e) {
          cb('username', 'Username is taken');
        }
      },
      [dispatch],
    ),
    500,
  );

  return (
    <>
      <Button
        startIcon={
          <SvgIcon fontSize="small">
            <PersonAddAltIcon />
          </SvgIcon>
        }
        onClick={handleOpen}
        variant="contained"
      >
        Add
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create user</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnBlur={false}
        >
          {(props) => (
            <Form>
              <DialogContent>
                <Field
                  as={TextField}
                  helperText={<ErrorMessage name="username" />}
                  error={props.errors.username && props.touched.username}
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  onChange={(e) => {
                    props.setFieldValue('username', e.target.value);
                    handleCheckUsername(e.target.value, props.setFieldError);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ gap: 1, px: 1 }}>
                        <IconButton
                          aria-label="generate username"
                          edge="end"
                          onClick={() => {
                            props.setFieldValue(
                              'username',
                              faker.internet.displayName(),
                            );
                          }}
                        >
                          <BadgeIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  as={TextField}
                  helperText={<ErrorMessage name="discord" />}
                  error={props.errors.discord && props.touched.discord}
                  margin="normal"
                  fullWidth
                  id="discord"
                  label="Discord"
                  name="discord"
                />
                <Field
                  as={TextField}
                  helperText={<ErrorMessage name="password" />}
                  error={props.errors.password && props.touched.password}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ gap: 1, px: 1 }}>
                        <IconButton
                          aria-label="generate password"
                          edge="end"
                          onClick={() => {
                            props.setFieldValue(
                              'password',
                              faker.internet.password({
                                length: 12,
                                pattern:
                                  /[a-zA-Z0-9~`!@#$%^&*()_+={}[\]:;<>,./|\\'"]/,
                              }),
                            );
                          }}
                        >
                          <EnhancedEncryptionIcon />
                        </IconButton>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={loading} type="submit">
                  Create
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default CreateModal;
