import { Avatar, Box, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useToggler } from 'hooks/use-toggler';
import AccountPopover from '../AccountPopover/AccountPopover';

const TOP_NAV_HEIGHT = 64;

const TopNav = () => {
  const accountPopover = useToggler();

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
          position: 'sticky',
          left: 0,
          top: 0,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="center"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Avatar
            onClick={accountPopover.handleOpen}
            ref={accountPopover.anchorRef}
            sx={{
              cursor: 'pointer',
              height: 40,
              width: 40,
            }}
            src="/assets/avatars/avatar-anika-visser.png"
          />
        </Stack>
      </Box>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
    </>
  );
};

export default TopNav;
