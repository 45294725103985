import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Chip } from '@mui/material';
import moment from 'moment';
import { ROLES, STATUSES } from 'config/users';
import UpdateModal from './_update';
import { blockUser, removeUser, unblockUser } from 'store/users';
import { enqueueSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

const getRowId = (row) => row._id;

const UsersTable = ({ loading, rows, rowCount, paginationModel, onChange }) => {
  // State for the dialog
  const [open, setOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [currentUsername, setCurrentUsername] = useState(null);

  const dispatch = useDispatch();

  const handleOpen = useCallback((action, id, username) => {
    setCurrentAction(action);
    setCurrentId(id);
    setCurrentUsername(username);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleConfirm = useCallback(() => {
    if (currentAction === 'block') {
      dispatch(blockUser(currentId));
    } else if (currentAction === 'unblock') {
      dispatch(unblockUser(currentId));
    } else if (currentAction === 'remove') {
      dispatch(removeUser(currentId));
    }
    setOpen(false);
  }, [dispatch, currentAction, currentId]);

  // Modify block and unblock functions
  const block = useCallback(
    (id, username) => {
      handleOpen('block', id, username);
    },
    [handleOpen],
  );

  const unblock = useCallback(
    (id, username) => {
      handleOpen('unblock', id, username);
    },
    [handleOpen],
  );

  const remove = useCallback(
    (id, username) => {
      handleOpen('remove', id, username);
    },
    [handleOpen],
  );

  const handlePaginationChange = useCallback(
    (model) => {
      onChange((params) => ({ ...params, ...model }));
    },
    [onChange],
  );

  // noinspection JSUnusedGlobalSymbols
  const columns = useMemo(
    () => [
      {
        field: 'discord',
        headerName: 'Discord',
        filterable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: ({ value }) => value || '-',
      },
      {
        field: 'passwordStr',
        headerName: 'Password',
        filterable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: ({ value }) => value || '-',
      },
      {
        field: 'username',
        headerName: 'Username',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
      },
      {
        field: 'roles',
        headerName: 'Role',
        sortable: false,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ value }) => {
          const role = value[0].name;

          return (
            role && (
              <Chip
                label={role}
                color={role === ROLES.ADMIN ? 'primary' : 'success'}
              />
            )
          );
        },
      },
      {
        field: 'blockedAt',
        headerName: 'Status',
        sortable: false,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: ({ value }) => {
          const status = value ? STATUSES.BLOCKED : STATUSES.ACTIVE;

          return (
            <Chip
              label={status.label}
              color={status.color}
              variant="outlined"
            />
          );
        },
      },
      {
        field: 'lastEnteredAt',
        headerName: 'Last Entered',
        filterable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: ({ value }) =>
          value ? moment(value).format('DD/MM/YYYY HH:mm') : '-',
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        filterable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm'),
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'center',
        headerAlign: 'center',
        minWidth: 110,
        getActions: ({ row }) => {
          const actions = [<UpdateModal userId={row._id} />];
          if (row.roles[0].name !== ROLES.ADMIN) {
            if (!row.blockedAt) {
              actions.push(
                <GridActionsCellItem
                  label="Block"
                  icon={<LockPersonIcon />}
                  onClick={() => block(row._id, row.username)}
                />,
              );
            } else {
              actions.push(
                <GridActionsCellItem
                  label="Unblock"
                  icon={<LockOpenIcon />}
                  onClick={() => unblock(row._id, row.username)}
                />,
              );
            }
            actions.push(
              <GridActionsCellItem
                label="Delete"
                icon={<DeleteIcon />}
                onClick={() => remove(row._id, row.username)}
              />,
            );
          } else {
            actions.push(
              <GridActionsCellItem
                icon={<LockPersonIcon />}
                label="Block"
                onClick={() =>
                  enqueueSnackbar('You cannot block an admin', {
                    variant: 'error',
                  })
                }
              />,
            );
          }

          return actions;
        },
      },
    ],
    [block, unblock, remove],
  );

  return (
    <>
      <DataGrid
        getRowId={getRowId}
        columns={columns}
        rows={rows}
        loading={loading}
        rowCount={rowCount}
        pageSizeOptions={[paginationModel.pageSize]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={handlePaginationChange}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {currentAction} user{' '}
            <span style={{ fontWeight: 'bold' }}>{currentUsername}</span>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UsersTable;
