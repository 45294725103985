import { Card, Stack, TextField } from '@mui/material';
import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';

const Search = ({ onChange }) => {
  const handleChange = useCallback(
    (field) => (e) => {
      onChange((current) => ({ ...current, [field]: e.target.value }));
    },
    [onChange],
  );

  return (
    <Card sx={{ p: 2 }}>
      <Stack direction="row" spacing={2}>
        <TextField
          label="Username"
          onChange={debounce(handleChange('username'), 500)}
        />
        <TextField
          label="Discord"
          onChange={debounce(handleChange('discord'), 500)}
        />
      </Stack>
    </Card>
  );
};

export default Search;
