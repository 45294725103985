import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirect } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { getMe, selectAuth, selectIs } from 'store/auth';
import AdminDashboard from 'layouts/AdminDashboard/AdminDashboard';
import UserDashboard from 'layouts/UserDashboard/UserDashboard';
import { ROLES } from 'config/users';

const AuthGuard = () => {
  const dispatch = useDispatch();
  const { authenticated, loading } = useSelector(selectAuth);
  const is = useSelector(selectIs);

  useEffect(() => {
    if (!authenticated) {
      // noinspection JSUnresolvedFunction
      dispatch(getMe()).catch(() => redirect('/login'));
    }
  }, [dispatch, authenticated]);

  if (loading) {
    return (
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return is(ROLES.USER) ? <UserDashboard /> : <AdminDashboard />;
};

export default AuthGuard;
