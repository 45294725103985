import { Link } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const NotFoundPage = () => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      minHeight: '100%',
    }}
  >
    <Container maxWidth="md">
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            mb: 3,
            textAlign: 'center',
          }}
        >
          <img
            alt="Under development"
            src="/error-404.png"
            style={{
              display: 'inline-block',
              maxWidth: '100%',
              width: 400,
            }}
          />
        </Box>
        <Typography align="center" sx={{ mb: 3 }} variant="h3">
          404: The page you are looking doesn`t exist
        </Typography>
        <Typography align="center" color="text.secondary" variant="body1">
          You either tried some shady route or you came here by mistake.
          Whichever it is, try using the navigation
        </Typography>
        <Button
          component={Link}
          to="/"
          startIcon={<ChevronLeftIcon />}
          sx={{ mt: 3 }}
          variant="contained"
        >
          Go back to home page
        </Button>
      </Box>
    </Container>
  </Box>
);

export default NotFoundPage;
