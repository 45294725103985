export const ROLES = {
  ADMIN: 'admin',
  USER: 'user',
};

export const STATUSES = {
  ACTIVE: {
    label: 'Active',
    color: 'success',
  },
  BLOCKED: {
    label: 'Blocked',
    color: 'error',
  },
};
