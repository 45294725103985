import { Box, Card, Stack } from '@mui/material';
import ArchivesTable from 'components/ArchivesTable/ArchivesTable';

const IndexPage = () => (
  <Stack spacing={3}>
    <Card>
      <Box sx={{ minWidth: 800 }}>
        <ArchivesTable />
      </Box>
    </Card>
  </Stack>
);

export default IndexPage;
