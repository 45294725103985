export const ROOMS = [
  '888Poker',
  'GGNetwork',
  'PokerStars',
  'PokerStars(FR-ES-PT)',
  'Winamax.fr',
  'WPN',
  'iPoker',
  'Chico',
  'Coin Poker',
];
