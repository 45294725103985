import { enqueueSnackbar } from 'notistack';
import { apiCallFailed } from '../api';
import { logoutSucceeded } from '../auth';

export const apiError =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== apiCallFailed.type) return next(action);

    const error = action.payload;

    if (error.response) {
      if (error.response.status === 403) {
        dispatch(logoutSucceeded());
      } else if (error.response.status === 500) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }
    }
  };

export default apiError;
