import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import store from 'store';
import './App.css';
import { createTheme } from './theme';
import AuthGuard from './guards/AuthGuard';
import LoginPage from './pages/login';

const App = () => {
  const theme = createTheme();

  return (
    <div className="App">
      <SnackbarProvider>
        <Provider store={store()}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <BrowserRouter>
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="*" element={<AuthGuard />} />
                </Routes>
              </BrowserRouter>
            </LocalizationProvider>
          </ThemeProvider>
        </Provider>
      </SnackbarProvider>
    </div>
  );
};

export default App;
