import axios from 'axios';
import { apiCallBegan, apiCallFailed, apiCallSuccess } from '../api';

export const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== apiCallBegan.type) return next(action);

    const {
      url,
      headers = {},
      method = 'get',
      params,
      data,
      onStart,
      onUploadProgress,
      onSuccess,
      onError,
      onEnd,
    } = action.payload;

    if (onStart) dispatch(onStart());

    next(action);

    try {
      const response = await axios.request({
        baseURL: process.env.REACT_APP_BASE_URL,
        url,
        method,
        params,
        data,
        headers,
        withCredentials: true,
        onUploadProgress,
      });

      dispatch(apiCallSuccess(response.data));

      if (onSuccess) {
        dispatch(onSuccess(response.data));
      }

      return response.data;
    } catch (e) {
      if (e.response) {
        dispatch(apiCallFailed(e));
      }

      if (onError) {
        dispatch(onError(e));
      } else {
        throw e;
      }
    } finally {
      if (onEnd) {
        dispatch(onEnd());
      }
    }
  };

export default api;
