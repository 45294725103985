// noinspection JSCheckFunctionSignatures,JSValidateTypes

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { getAll, getSignedUrl, remove, selectFiles } from 'store/files';
import { selectIs } from 'store/auth';
import { ROLES } from 'config/users';
import { Card, MenuItem, Select } from '@mui/material';
import { ROOMS } from 'config/files';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const getRowId = (row) => row._id;

const ArchivesTable = () => {
  const [open, setOpen] = useState(false);
  const [currentFileId, setCurrentFileId] = useState(null);
  const [currentFilename, setCurrentFilename] = useState(null);

  const dispatch = useDispatch();
  const { list, loading } = useSelector(selectFiles);
  const is = useSelector(selectIs);
  const [params, setParams] = useState({
    page: 0,
    pageSize: 40,
    room: 'empty',
  });

  useEffect(() => {
    dispatch(
      getAll({
        page: params.page + 1,
        room: params.room === 'empty' ? undefined : params.room,
      }),
    );
  }, [dispatch, params]);

  const handleOpen = (id, filename) => {
    setCurrentFileId(id);
    setCurrentFilename(filename);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePaginationChange = useCallback((model) => {
    setParams((current) => ({ ...current, ...model }));
  }, []);

  const handleFilterChange = useCallback((e) => {
    setParams((current) => ({ ...current, room: e.target.value }));
  }, []);

  const download = useCallback(
    async (key) => {
      try {
        const { data } = await dispatch(getSignedUrl(key));

        window.open(data.url, '_blank');
      } catch (e) {
        enqueueSnackbar(e.response?.data.message, { variant: 'error' });
      }
    },
    [dispatch],
  );

  const removeOne = useCallback(
    async (id) => {
      try {
        await dispatch(remove(id));
        enqueueSnackbar('Removed successfully', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(e.response?.data.message, { variant: 'error' });
      }
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        field: 'room',
        headerName: 'Room',
        flex: 1,
        sortable: false,
        filterable: false,
      },
      {
        field: 'filename',
        headerName: 'Filename',
        flex: 1,
        sortable: false,
        filterable: false,
      },
      {
        field: 'size',
        headerName: 'Size',
        sortable: false,
        filterable: false,
        valueFormatter: ({ value }) => formatBytes(value),
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: ({ row }) => {
          const actions = [
            <GridActionsCellItem
              label="Download"
              icon={<DownloadIcon />}
              onClick={() => download(row.filename)}
            />,
          ];

          if (is(ROLES.ADMIN)) {
            actions.push(
              <GridActionsCellItem
                label="Delete"
                icon={<DeleteIcon />}
                onClick={() => handleOpen(row._id, row.filename)}
              />,
            );
          }

          return actions;
        },
      },
    ],
    [download, is, removeOne],
  );

  const confirmDelete = () => {
    removeOne(currentFileId);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Deletion'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the file{' '}
            <span style={{ fontWeight: 'bold' }}>{currentFilename}</span>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Card sx={{ p: 2 }}>
        <Select
          sx={{ minWidth: 300 }}
          onChange={handleFilterChange}
          value={params.room}
        >
          <MenuItem value="empty">Filter by room</MenuItem>
          {ROOMS.map((room) => (
            <MenuItem key={room} value={room}>
              {room}
            </MenuItem>
          ))}
        </Select>
      </Card>
      <DataGrid
        autoHeight
        getRowId={getRowId}
        columns={columns}
        loading={loading}
        rows={list.docs}
        rowCount={list.totalDocs}
        pageSizeOptions={[params.pageSize]}
        paginationModel={params}
        paginationMode="server"
        onPaginationModelChange={handlePaginationChange}
      />
    </>
  );
};

export default ArchivesTable;
