// noinspection JSCheckFunctionSignatures

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'sessions',
  initialState: {
    list: {
      totalDocs: 0,
      hasNextPage: true,
      docs: [],
    },
    loading: false,
    error: null,
  },
  reducers: {
    sessionsRequestStarted: (sessions) => {
      sessions.loading = true;
    },
    sessionsRequestFailed: (sessions, action) => {
      sessions.error = action.payload.data;
    },
    sessionsRequestEnded: (sessions) => {
      sessions.loading = false;
    },
    sessionsReceived: (sessions, action) => {
      sessions.list = action.payload.data;
    },
  },
});

export const {
  sessionsRequestStarted,
  sessionsRequestFailed,
  sessionsRequestEnded,
  sessionsReceived,
} = slice.actions;
export default slice.reducer;

//api calls
export const getAll = (params) =>
  apiCallBegan({
    url: '/sessions',
    params,
    onStart: sessionsRequestStarted,
    onSuccess: sessionsReceived,
    onError: sessionsRequestFailed,
    onEnd: sessionsRequestEnded,
  });

export const remove = (id) =>
  apiCallBegan({
    url: `/sessions/${id}`,
    method: 'DELETE',
    onStart: sessionsRequestStarted,
    onSuccess: () => getAll(),
    onError: sessionsRequestFailed,
    onEnd: sessionsRequestEnded,
  });

//selector
export const selectSessions = createSelector(
  (state) => state.sessions,
  (files) => ({
    list: files.list,
    loading: files.loading,
  }),
);
