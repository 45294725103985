import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Stack, Typography } from '@mui/material';
import { getAll, selectList } from 'store/users';
import UsersTable from './_table';
import CreateModal from './_create';
import Search from './_search';

const UsersPage = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(selectList);
  const [params, setParams] = useState({ page: 0, pageSize: 40 });

  useEffect(() => {
    dispatch(getAll({ ...params, page: params.page + 1 }));
  }, [dispatch, params]);

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between" spacing={4}>
        <Stack spacing={1}>
          <Typography variant="h4">Users</Typography>
        </Stack>
        <div>
          <CreateModal loading={loading} />
        </div>
      </Stack>
      <Card>
        <Box sx={{ minWidth: 800 }}>
          <Search params={params} onChange={setParams} />
          <UsersTable
            loading={loading}
            rows={list.docs}
            rowCount={list.totalDocs}
            paginationModel={params}
            onChange={setParams}
          />
        </Box>
      </Card>
    </Stack>
  );
};

export default UsersPage;
