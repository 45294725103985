import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { trackVisit } from 'store/users';
import IndexPage from 'pages/index';
import NotFoundPage from 'pages/404';
import TopNav from './TopNav';
import { useEffect } from 'react';

const LayoutRoot = styled('div')({
  display: 'flex',
  maxWidth: '100%',
});

const LayoutContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const UserDashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trackVisit());
  }, [dispatch]);

  return (
    <>
      <TopNav />
      <LayoutRoot>
        <LayoutContainer>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 8,
            }}
          >
            <Container maxWidth="xl">
              <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="/*" element={<NotFoundPage />} />
              </Routes>
            </Container>
          </Box>
        </LayoutContainer>
      </LayoutRoot>
    </>
  );
};

export default UserDashboard;
